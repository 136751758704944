<template>
  <div class="demand">
    <Breadcrumb :data="breData" :is$t="true" />
    <div class="container">
      <Title :title="$t(&quot;f01427c&quot;)" :des="$t('ae48d27')" />
      <ReleaseDesk />
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import ReleaseDesk from '@/components/ReleaseDesk'
import Title from '@/components/Title'
// const i18nPrefix = {
//   label: 'account.register.releaseForm.lable.',
//   pla: 'account.register.releaseForm.pla.',
// }
export default {
  name: 'DeskRelease',
  components: {
    Breadcrumb,
    ReleaseDesk,
    Title,
  },
  data() {
    return {
      breData: [
        {
          name: "f01427c",
          path: '/service/desk',
        },
        {
          name: "3f8bf39",
          path: '/service/desk/release',
        },
      ],
    }
  },
  computed: {},
  watch: {},
  created() {},
  methods: {},
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
.demand {
  width: @uni-width;
  margin: 0 auto;
  .container {
    width: 100%;
    background: #fff;
  }
}
</style>
